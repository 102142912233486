// 
// user.scss
// Use this to write your custom SCSS
//

.text-warning {
    color: #f2ab27 !important;
}

.bg-warning {
    background-color: #f2ab27 !important;
}

.btn-primary {
    color: #FFFFFF;
    background-color: #f2ab27;
    border-color: #f2ab27;
}

.btn-primary:hover {
    color: #FFFFFF;
    background-color: #e39c18;
    border-color: #e39c18;
}

.navbar-nav .dropdown-img-start::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(242, 171, 39, 0.3); 
    border-radius: inherit;
}


.text-primary {
    color: #e39c18 !important;
}


.dropdown-header {
    display: block;
    padding: 1.625rem 1.75rem;
    margin-bottom: 0;
    font-size: 0.9375rem;
    color: #e39c18 !important;
    white-space: nowrap;
}



.flickity-prev-next-button {
 
    background-color: #f2ab27;
 
    
}



.flickity-prev-next-button:hover, .flickity-prev-next-button:focus {
    background-color: #f7b800;
   
}

.text-success{
    color: #f7b800  !important;;
}

.badge.bg-success-soft {
    background-color: rgba(66, 186, 150, 0.1);
    color: #f7b800;
}


.badge.bg-success-soft {
    background-color: "#b98347";
    color: #42BA96;
}

.btn-success {
    color: #FFFFFF;
    background-color: #f2ab27;
    border-color: #f2ab27;
    box-shadow: 0 0;
}


.btn-success:hover {
    color: #FFFFFF;
    background-color: #e39c18;
    border-color: #e39c18;
}


.modal-backdrop{

    background-color: black;
}

.btn-primary:active{
    background-color:#cf8d12;
    border-color: #cf8d12;
}

.bg-dark {
    background-color: #282828 !important;
}

.text-dark {
    color: #282828 !important;
}

.text-gray-700 {
    color: #b1b1b1 !important;
}

.text-muted {
    color: #b1b1b1 !important;
}



.form-check-input:checked {
    background-color: #00bb42;
    border-color: #00bb42;
}


.overlay-dark::before {
    background-color: #000000;
}

// INÍCIO TIMELINE 


@media(min-width:768px) {
  .form-link {
    margin-top: 15px
  }

  .form-link a {
    font-size: 18px
  }
}


.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto
}

.cd-container:after {
  content: '';
  display: table;
  clear: both
}

#cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 0;
  margin-bottom: 0
}

#cd-timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 2px;
  background: #f2ab27
}

#cd-timeline:after {
  content: '';
  position: absolute;
  top: 0;
  left: 11px;
  height: 16px;
  width: 16px;
  background: #f2ab27;
  border-radius: 100%
}

@media only screen and (min-width:1170px) {
  #cd-timeline:before {
    left: 50%;
    margin-left: -2px
  }

  #cd-timeline:after {
    left: 50%;
    transform: translateX(-54%)
  }
}

.cd-timeline-block {
  position: relative;
  margin: 2em 0
}

.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both
}

.cd-timeline-block:first-child {
  margin-top: 0
}

.cd-timeline-block:last-child {
  margin-bottom: 0
}

@media only screen and (min-width:1170px) {
  .cd-timeline-block {
    margin: 4em 0
  }

  .cd-timeline-block:first-child {
    margin-top: 0
  }

  .cd-timeline-block:last-child {
    margin-bottom: 0
  }
}

.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background: #168c6a;
  border-radius: 50%;
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05)
}

@media only screen and (min-width:1170px) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden
  }

  .cd-timeline-img.is-hidden {
    visibility: hidden
  }

  .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 .6s;
    -moz-animation: cd-bounce-1 .6s;
    animation: cd-bounce-1 .6s
  }
}



.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: #f7f7f7;
  border-color: #00bb42;
  border-radius: .25em;
  padding: 1em;
  box-shadow: 0 3px 0 #c5c5c5;

}

.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both
}

.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
  display: inline-block
}

.cd-timeline-content p {
  margin: 0 0;
  font-size: 14px
}

.cd-timeline-content .no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb
}

.cd-timeline-content .cd-date {
  float: left;
  padding: .8em 0;
  opacity: .7
}

.cd-timeline-content:before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #fff
}

@media only screen and (min-width:1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: .7em;
    width: 44%
  }

  .cd-timeline-content:before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: #fff
  }

  .cd-timeline-content .cd-read-more {
    float: left
  }

  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #fff
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right
  }

  .cd-timeline-content.is-hidden {
    visibility: hidden
  }

  .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 .6s;
    -moz-animation: cd-bounce-2 .6s;
    animation: cd-bounce-2 .6s
  }
}

@media only screen and (min-width:1170px) {
  .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse .6s;
    -moz-animation: cd-bounce-2-inverse .6s;
    animation: cd-bounce-2-inverse .6s
  }
}

.img-responsive,
.thumbnail>img,
.thumbnail a>img,
.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
  display: block;
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
}

bounce-in{
    background-color: #00bb42;
}

// FIM TIMELINE 